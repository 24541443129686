import wx from 'weixin-js-sdk'
import axios from "axios";
function config({url,jsApiList},callback) {
    axios.post('wechatJsConfig', {
        "apis": jsApiList,
        url
    },{
        headers:{
            Authorization:localStorage.getItem('token')
        }
    }).then((res) => {
        if (res.data.code === '0000') {
            let configs = JSON.parse(res.data.content.config);
            // that.$router.push('detail')
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: configs.appId, // 必填，公众号的唯一标识
                timestamp: configs.timestamp, // 必填，生成签名的时间戳
                nonceStr: configs.nonceStr, // 必填，生成签名的随机串
                signature: configs.signature,// 必填，签名
                jsApiList // 必填，需要使用的JS接口列表
            });
            //通过ready接口处理成功验证
            wx.ready(function () {
                callback?callback():'';
            })
            wx.error(function (err) {
                console.log(err)
                // alert(JSON.stringify(err))
                throw new Error(err)
            })
        }
    })
}
export default config;
